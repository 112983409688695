<template>
  <div class="admin-app-container">
    <Loading v-if="loading" />
    <Header />
    <div class="pt-1 pa-sm-6 app-container">
      <h2 class="pl-2 pr-2 pa-sm-2 text-center">Validate Discount Coupon</h2>
      <div class="d-flex justify-center">
        <v-form
          ref="formAdmin"
          class="pt-4 formulario"
          onSubmit="return false;"
        >
          <v-row class="pa-4">
            <v-col cols="12" xs="12" sm="12" md="6" class="pa-0 d-flex">
              <v-text-field
                ref="refCodigo"
                label="Insert code"
                dense
                v-model="codigo"
                clearable
                outlined
                :rules="regrasCupom"
                class="mr-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="pa-0 d-flex">
              <v-btn
                color="green"
                outlined
                class="text-caption mr-2"
                @click="validarCupom"
                height="40px"
                type="submit"
                :disabled="!codigo"
              >
                <v-icon class="text-caption">mdi-check-decagram-outline</v-icon>
                Validate Code
              </v-btn>
              <v-btn
                color="green"
                outlined
                class="text-caption"
                @click="lerQRCode"
                height="40px"
                type="submit"
              >
                <v-icon class="text-caption">mdi-qrcode-scan</v-icon>
                Read QRCode
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <ModalSucesso
        :mensagem="mensagemAlerta"
        :alertaSucesso="alertaSucesso"
        :titulo="mensagemTitulo"
        @ocultarSucesso="ocultarSucesso"
        :cor="corModalSucesso"
      />
      <MensagemErro
        :mensagemAlerta="mensagemAlerta"
        :alertaErro="alertaErro"
        @ocultarErro="alertaErro = false"
        :login="false"
        :timeout="3000"
      />
      <v-row justify="center" v-if="modalQRCode">
      <v-dialog v-model="modalQRCode" max-width="450px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Read QRCode</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeModalQRCode">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-4">
            <qrcode-stream @decode="onDecode"></qrcode-stream>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading.vue";
import Header from "../../components/header.vue";
import VoucherService from "../../service/voucher-service.js";
import ModalSucesso from "../../components/modal-sucesso.vue";
import MensagemErro from "../../components/mensagem-erro.vue";
import StorageService from "../../service/storage-service.js";
import { QrcodeStream } from 'vue-qrcode-reader'

const voucherService = new VoucherService();
const storageService = new StorageService();

export default {
  components: {
    Loading,
    Header,
    ModalSucesso,
    MensagemErro,
    QrcodeStream
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      loading: false,
      codigo: null,
      erroPolitica: null,
      corModalSucesso: null,
      validar: false,
      capabilities: null,
      modalQRCode: false
    };
  },
  methods: {
    closeModalQRCode(){
      this.modalQRCode = false;
    },
    lerQRCode(){
      this.modalQRCode = true;
    },
    async onInit (promise) {
      this.loading = true;
      try {
        this.capabilities = await promise
      } catch {
        this.mensagemAlerta = "Error loading device camera";
        this.alertaErro = true;
      } finally {
        this.loading = false;
      }
    },
    async onDecode (decodedString) {
      this.modalQRCode = false;
      this.loading = true;
      const token = decodedString.split("?token=")[1];
      let result = await voucherService.validaQRCodeVoucher(token);
      this.retornoMensagem(result?.data.mensagem, result?.statusCode);
    },
    async validarCupom() {
      this.validar = true;
      this.corModalSucesso = null;
      setTimeout(async () => {
        if (this.$refs.formAdmin.validate()) {
          this.loading = true;
          let result = await voucherService.validarCupom(this.codigo);
          this.retornoMensagem(result?.data.mensagem, result?.statusCode);
        }
      }, 100);
    },
    retornoMensagem(mensagem, status) {
      this.mensagemAlerta = mensagem;
      this.mensagemTitulo = status == 200 ? "Coupon Validated Successfully" : "Error validating coupon";
      this.corModalSucesso = status == 200 ? null : "red";
      this.alertaSucesso = true;
      this.loading = false;
      this.codigo = null;
      this.$refs.formAdmin.resetValidation();
      this.$refs.refCodigo.focus();
      this.validar = false;
    },
    ocultarSucesso() {
      this.alertaSucesso = false;
    },
  },
  mounted: function () {
    if(this.$refs.refCodigo){
      setTimeout(() => {
        this.$refs.refCodigo.focus();
      }, 250);
    }
  },
  created: async function () {
    const token = this.$route.query.token;
    if(token){
      const result = await voucherService.validaQRCodeVoucher(token);
      this.retornoMensagem(result?.data.mensagem, result?.statusCode);
    }
    await storageService.validarToken();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    regrasCupom() {
      const rules = [];
      if (this.validar) {
        const rule = (v) => !!v || "Code is a required field";
        rules.push(rule);
      }
      return rules;
    },
  },
};
</script>
<style scoped>
.admin-app-container {
  height: 100vh;
}
.formulario {
  width: 100%;
  max-width: 600px;
}
@media (max-width: 600px) {
  .formulario {
    width: unset;
    max-width: 600px;
  }
}
</style>